const remoteBackend = "https://api.stylam.com"
const localBackend = "http://localhost:1337"

const backendHost = remoteBackend;
  // process.env.TESTING === "production" ? remoteBackend : localBackend

module.exports = {
  backendHost,
  remoteBackend,
  localBackend,
}
